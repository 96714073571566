window.addEventListener('load', e => {
    registerSW();
});

async function registerSW() {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register('/sw.js');
        } catch (e) {
            console.log('Service worker registration failed.')
            // alert('ServiceWorker registration failed. Sorry about that.');
        }
    } else {
        console.log('Service worker registration failed.')
    }
}
