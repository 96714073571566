jQuery(function ($) {
    $("#departmentsFilter").on("change", function () {
        let value = $(this).val();
        $("#locationsFilter").val('All')
        if (value === 'All' || value === '') {
            $(".jobListing").each(function (listing) {
                $(this).show()
            })
        } else {
            $(".jobListing").each(function (listing) {
                if ($($(".jobListing")[listing]).data('department') !== value) {
                    $(this).hide()
                } else {
                    $(this).show()
                }
            });
        }
    });
    $("#locationsFilter").on("change", function () {
        let value = $(this).val();
        $("#departmentsFilter").val('All')
        if (value === 'All' || value === '') {
            $(".jobListing").each(function (listing) {
                $(this).show()
            })
        } else {
            $(".jobListing").each(function (listing) {
                if ($($(".jobListing")[listing]).data('location') !== value) {
                    $(this).hide()
                } else {
                    $(this).show()
                }
            });
        }
    });


});
