$(function () {

    $('[data-toggle="popover"]').popover()
    $('[data-toggle="tooltip"]').tooltip()

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.main-navbar').outerHeight();

    $(window).scroll(function (event) {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 150);

    function hasScrolled() {
        var st = $(this).scrollTop();

        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $('.main-navbar').removeClass('nav-down').addClass('nav-up');
            $('.extended-nav').removeClass('extended-nav-down').addClass('extended-nav-up');
            $('#ribbon').removeClass('ribbon-extended-nav-down').addClass('ribbon-extended-nav-up');
        	$('#fly-out-contact-form_open').fadeIn()
            // $('#fly-out-contact-form_open').removeClass('up').addClass('down');
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                $('.main-navbar').removeClass('nav-up').addClass('nav-down');
                $('.extended-nav').removeClass('extended-nav-up').addClass('extended-nav-down');
                $('#ribbon').removeClass('ribbon-extended-nav-up').addClass('ribbon-extended-nav-down');
                // $('#fly-out-contact-form_open').removeClass('down').addClass('up');
            }
        }

        lastScrollTop = st;
    }

	//close it on page load 
        $('#fly-out-contact-form_open').fadeOut()
        //await new Promise(done => setTimeout(() => done(), 300));


    $(".menu-toggle").click(function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });

    $("#fly-out-contact-form_open").on('click mouseover touchstart', function () {
        $('#fly-out-contact-form_open').removeClass('up').addClass('down')
        $('#fly-out-contact-form-card').toggleClass('closed')
    })

    $("#fly-out-contact-form_close").on('click touchstart', async function () {
        $('#fly-out-contact-form-card').toggleClass('closed')
        await new Promise(done => setTimeout(() => done(), 300));
        $('#fly-out-contact-form_open').removeClass('down').addClass('up')
    })

});
