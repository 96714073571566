//console.log("N15");
var mLB = null; 
var mLBoptions = { "alwaysShowClose": true, height:800 } 
$(document).ready( function() { 

    function _createCookie(name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }
    function _readCookie(name) {
        var nameEQ = encodeURIComponent(name) + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    }

    /*  // pop-over disabled 
	popcookie = _readCookie("pop"); 
    if(!popcookie){
        xint=setInterval(function(){ 
                mLB = $('#e900'); 
                mLB.ekkoLightbox ({
                    alwaysShowClose: true,
                    maxWidth: 900,
                    width: 900,
                    onShown: function() {
                        _createCookie("pop", 1, 30);
                        setTimeout("clearInterval(xint);", 1050);
                    },
                    onNavigate: function(direction, itemIndex){
                    }
                });

            }, 10000); 
        setTimeout("clearInterval(xint);", 10500);
    }
    */
}); 


